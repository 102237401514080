@font-face {
  font-family: 'fontello';
  src: url('font/fontello.eot?3678844');
  src: url('font/fontello.eot?3678844#iefix') format('embedded-opentype'),
       url('font/fontello.woff2?3678844') format('woff2'),
       url('font/fontello.woff?3678844') format('woff'),
       url('font/fontello.ttf?3678844') format('truetype'),
       url('font/fontello.svg?3678844#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?3678844#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-360:before { content: '\e800'; } /* '' */
.icon-about-w:before { content: '\e801'; } /* '' */
.icon-add-to-calendar:before { content: '\e802'; } /* '' */
.icon-add:before { content: '\e803'; } /* '' */
.icon-ads-popup:before { content: '\e804'; } /* '' */
.icon-agents:before { content: '\e805'; } /* '' */
.icon-arrow-l:before { content: '\e806'; } /* '' */
.icon-arrow-r:before { content: '\e807'; } /* '' */
.icon-attachment:before { content: '\e808'; } /* '' */
.icon-b2b-and-b2c:before { content: '\e809'; } /* '' */
.icon-befaringsbekreftelse:before { content: '\e80a'; } /* '' */
.icon-bid-round-handled-by-broker:before { content: '\e80b'; } /* '' */
.icon-book-inspection:before { content: '\e80c'; } /* '' */
.icon-bt-shot-off-the-lust:before { content: '\e80d'; } /* '' */
.icon-calendar:before { content: '\e80e'; } /* '' */
.icon-chat:before { content: '\e80f'; } /* '' */
.icon-checkbox-normal:before { content: '\e810'; } /* '' */
.icon-checkbox-select:before { content: '\e811'; } /* '' */
.icon-close:before { content: '\e812'; } /* '' */
.icon-content-management:before { content: '\e813'; } /* '' */
.icon-contract-information:before { content: '\e814'; } /* '' */
.icon-copy:before { content: '\e815'; } /* '' */
.icon-dashboard:before { content: '\e816'; } /* '' */
.icon-delete:before { content: '\e817'; } /* '' */
.icon-digital-sales-assignment--find-ad:before { content: '\e818'; } /* '' */
.icon-digital-sales-assignment:before { content: '\e819'; } /* '' */
.icon-disclaimer:before { content: '\e81a'; } /* '' */
.icon-display-package:before { content: '\e81b'; } /* '' */
.icon-documents:before { content: '\e81c'; } /* '' */
.icon-dot:before { content: '\e81d'; } /* '' */
.icon-download:before { content: '\e81e'; } /* '' */
.icon-drop-down:before { content: '\e81f'; } /* '' */
.icon-drop-normal:before { content: '\e820'; } /* '' */
.icon-ds-contact:before { content: '\e821'; } /* '' */
.icon-ds-import:before { content: '\e822'; } /* '' */
.icon-ds-property:before { content: '\e823'; } /* '' */
.icon-edit-profile:before { content: '\e824'; } /* '' */
.icon-edit:before { content: '\e825'; } /* '' */
.icon-email:before { content: '\e826'; } /* '' */
.icon-energy-labeling:before { content: '\e827'; } /* '' */
.icon-experienced-real-estate-agent:before { content: '\e828'; } /* '' */
.icon-facebook:before { content: '\e829'; } /* '' */
.icon-faq:before { content: '\e82a'; } /* '' */
.icon-filter:before { content: '\e82b'; } /* '' */
.icon-financing:before { content: '\e82c'; } /* '' */
.icon-gallery:before { content: '\e82d'; } /* '' */
.icon-history:before { content: '\e82e'; } /* '' */
.icon-home:before { content: '\e82f'; } /* '' */
.icon-info-from-seller:before { content: '\e830'; } /* '' */
.icon-inspection-application:before { content: '\e831'; } /* '' */
.icon-instagram:before { content: '\e832'; } /* '' */
.icon-instructional:before { content: '\e833'; } /* '' */
.icon-jobs:before { content: '\e834'; } /* '' */
.icon-linked-in:before { content: '\e835'; } /* '' */
.icon-logo-for-placeholder:before { content: '\e836'; } /* '' */
.icon-logout:before { content: '\e837'; } /* '' */
.icon-make-an-offer:before { content: '\e838'; } /* '' */
.icon-marketing-material-print:before { content: '\e839'; } /* '' */
.icon-marketing-materials:before { content: '\e83a'; } /* '' */
.icon-marketing-package:before { content: '\e83b'; } /* '' */
.icon-menu:before { content: '\e83c'; } /* '' */
.icon-moovin:before { content: '\e83d'; } /* '' */
.icon-more:before { content: '\e83e'; } /* '' */
.icon-mortgage-offers:before { content: '\e83f'; } /* '' */
.icon-moving-guide:before { content: '\e840'; } /* '' */
.icon-my-order:before { content: '\e841'; } /* '' */
.icon-new_housing:before { content: '\e842'; } /* '' */
.icon-news:before { content: '\e843'; } /* '' */
.icon-notifications:before { content: '\e844'; } /* '' */
.icon-notifications_moovin:before { content: '\e845'; } /* '' */
.icon-obtaining-checking-documentation:before { content: '\e846'; } /* '' */
.icon-offers-on-insurance:before { content: '\e847'; } /* '' */
.icon-office-template:before { content: '\e848'; } /* '' */
.icon-open-externally:before { content: '\e849'; } /* '' */
.icon-order-cancel:before { content: '\e84a'; } /* '' */
.icon-photo:before { content: '\e84b'; } /* '' */
.icon-preparations:before { content: '\e84c'; } /* '' */
.icon-print-marketing:before { content: '\e84d'; } /* '' */
.icon-product-gallery:before { content: '\e84e'; } /* '' */
.icon-professional-photographer:before { content: '\e84f'; } /* '' */
.icon-profile:before { content: '\e850'; } /* '' */
.icon-radio-normal:before { content: '\e851'; } /* '' */
.icon-radio-select:before { content: '\e852'; } /* '' */
.icon-rate:before { content: '\e853'; } /* '' */
.icon-rating:before { content: '\e854'; } /* '' */
.icon-replace:before { content: '\e855'; } /* '' */
.icon-rewards:before { content: '\e856'; } /* '' */
.icon-sales-information:before { content: '\e857'; } /* '' */
.icon-sales-process:before { content: '\e858'; } /* '' */
.icon-search:before { content: '\e859'; } /* '' */
.icon-see-finn-ad:before { content: '\e85a'; } /* '' */
.icon-select-all:before { content: '\e85b'; } /* '' */
.icon-seller-interview:before { content: '\e85c'; } /* '' */
.icon-seller:before { content: '\e85d'; } /* '' */
.icon-send:before { content: '\e85e'; } /* '' */
.icon-settlement-information:before { content: '\e85f'; } /* '' */
.icon-sign-everywhere:before { content: '\e860'; } /* '' */
.icon-smart-digital-marketing:before { content: '\e861'; } /* '' */
.icon-star-half-fill:before { content: '\e862'; } /* '' */
.icon-star-outline:before { content: '\e863'; } /* '' */
.icon-statistics:before { content: '\e864'; } /* '' */
.icon-styling:before { content: '\e865'; } /* '' */
.icon-summary-from-befaring:before { content: '\e866'; } /* '' */
.icon-superusers:before { content: '\e867'; } /* '' */
.icon-t-sold:before { content: '\e868'; } /* '' */
.icon-takeover:before { content: '\e869'; } /* '' */
.icon-tasks-or-checklist:before { content: '\e86a'; } /* '' */
.icon-thank_you:before { content: '\e86b'; } /* '' */
.icon-tick-simple:before { content: '\e86c'; } /* '' */
.icon-tickmark:before { content: '\e86d'; } /* '' */
.icon-time:before { content: '\e86e'; } /* '' */
.icon-timeline:before { content: '\e86f'; } /* '' */
.icon-upgrades-in-property:before { content: '\e870'; } /* '' */
.icon-upload:before { content: '\e871'; } /* '' */
.icon-variable-utlegg:before { content: '\e872'; } /* '' */
.icon-vendors:before { content: '\e873'; } /* '' */
.icon-video:before { content: '\e874'; } /* '' */
.icon-view-general-icon:before { content: '\e875'; } /* '' */
.icon-view-pdf:before { content: '\e876'; } /* '' */
.icon-you-tube:before { content: '\e877'; } /* '' */
.icon-pwd-hide:before { content: '\e878'; } /* '' */
