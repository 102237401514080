/* This stylesheet generated by Transfonter (https://transfonter.org) on February 10, 2018 4:06 PM */

@font-face {
    font-family: 'Maax';
    src: url('Maax-Medium.eot');
    src: local('Maax Medium'), local('Maax-Medium'),
        url('Maax-Medium.eot?#iefix') format('embedded-opentype'),
        url('Maax-Medium.woff') format('woff'),
        url('Maax-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Maax';
    src: url('Maax-Italic.eot');
    src: local('Maax Italic'), local('Maax-Italic'),
        url('Maax-Italic.eot?#iefix') format('embedded-opentype'),
        url('Maax-Italic.woff') format('woff'),
        url('Maax-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Maax';
    src: url('Maax-BoldItalic.eot');
    src: local('Maax BoldItalic'), local('Maax-BoldItalic'),
        url('Maax-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Maax-BoldItalic.woff') format('woff'),
        url('Maax-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Maax';
    src: url('Maax-Black.eot');
    src: local('Maax Black'), local('Maax-Black'),
        url('Maax-Black.eot?#iefix') format('embedded-opentype'),
        url('Maax-Black.woff') format('woff'),
        url('Maax-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Maax';
    src: url('Maax.eot');
    src: local('Maax'),
        url('Maax.eot?#iefix') format('embedded-opentype'),
        url('Maax.woff') format('woff'),
        url('Maax.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Maax';
    src: url('Maax-Bold.eot');
    src: local('Maax Bold'), local('Maax-Bold'),
        url('Maax-Bold.eot?#iefix') format('embedded-opentype'),
        url('Maax-Bold.woff') format('woff'),
        url('Maax-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Maax';
    src: url('Maax-Mediumitalic.eot');
    src: local('Maax MediumItalic'), local('Maax-Mediumitalic'),
        url('Maax-Mediumitalic.eot?#iefix') format('embedded-opentype'),
        url('Maax-Mediumitalic.woff') format('woff'),
        url('Maax-Mediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
